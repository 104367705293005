import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import { withStyles } from '@mui/styles';

const styles = {
  tr: {
    '&:hover': {
    }
  }
};

const ArrayTextAnination = ({array}) => {
    return array.map((item, key) => 
            key == 2 &&
            <Typography key={key} variant="body2">
                {item}
            </Typography>
    )
};

const OutlinedCardBody = ({data: {h, text, type}}) => (
  <React.Fragment>
    <CardContent>
        <Grid container spacing={0} sx={{
          alignItems: 'center',
          justifyContent: 'left',
          paddingBottom: 1
      }}>
            <Typography variant="h5" component="div">
              {h}
            </Typography>
            {type &&
                <Typography sx={{ fontSize: 12, position: 'relative', ml: 2, border: 1, borderColor: 'pink', borderRadius: 5, paddingLeft: 1, paddingRight: 1}} color="pink">
                  {type}
                </Typography>
            }
      </Grid>
      <Typography variant="body2">
        {Array.isArray(text) ? <ArrayTextAnination array={text}/> : text }
      </Typography>
    </CardContent>
  </React.Fragment>
);

const OutlinedCard = ({data, classes, setCurrentIndexFn, currentIndex}) => {
    // console.log('data', data);
  return (
    <Box 
      className={`text-shadow ${classes.tr}`} 
      sx={{ minWidth: 275, maxWidth: 375,  margin: 0.5, boxShadow: currentIndex == data.id ? '0px 0px 2.5em 1px rgba(32, 117, 244, 0.58);' : '0px 0px 0.5em 0 rgb(27, 29, 32)' }}
      onClick={() => setCurrentIndexFn(data.id)}>
        <Card variant="outlined" sx={{ height: 175, minWidth: 200 }}>
            <OutlinedCardBody data={data} />
        </Card>
    </Box>
  );
}

export default withStyles(styles)(OutlinedCard)