import React, { useEffect } from 'react'
import {
    AppBar,
    Toolbar,
    Box,
    List,
    ListItem,
    Typography,
    styled,
    ListItemButton,
    ListItemText,
    Badge,
} from '@mui/material';
// menu
import DrawerItem from './DrawerItem';
// rotas
import { Link, useLocation } from 'react-router-dom';

import nav_logo from '../assets/nav_logo.svg';

// personalizacao
const StyledToolbar = styled(Toolbar)({
    display: 'flex',
    justifyContent: 'space-between',
});

const ListMenu = styled(List)(({ theme }) => ({
    display: 'none',
    [theme.breakpoints.up("sm")]: {
        display: "flex",
    },
}));

//rotas
const itemList = [
    {
        text: "Home",
        to: "/",
        isFromApp: true
    },
    {
        text: "Explore",
        to: "/about"
    },
    {
        text: "People",
        to: "/people"
    },
    {
        text: "Lab",
        to: "/lab"
    },
    {
        text: "FAQ",
        to: "/faq"
    },
    {
        text: "Contact",
        to: "/contact"
    }
];


const Navbar = () => {
    const location = useLocation();
    const pathname = location.pathname;
    useEffect(() => {
        if (pathname !== '/') {
            document.getElementById('app_bar_id').style['background-color'] = '#09090980';
        } else {
            document.getElementById('app_bar_id').style['background-color'] = '#090909';
        }
    }, [pathname]);

    useEffect(() => {
        setTimeout(() => {
            document.getElementById('app_bar_id').style['transform'] = 'translateY(0px)';
        }, 2000);
    }, []);

    return (
        <AppBar
            id='app_bar_id'
            component="nav"
            position="fixed"
            sx={{
                backgroundColor: '#090909',
                transition: '3s',
                transform: 'translateY(-90px)',
                width: '100% !important'
            }}
            elevation={0}
        >
            <StyledToolbar>
                <Typography
                    variant="h6"
                    component="h2"
                >
                    {/* <Link to='/' state={{ isFromApp: true }} style={{ color: 'white' }}>Metastability</Link> */}
                <img
                  src={nav_logo}
                  width={165}
                  height={25}
                  alt="Metastability"
                />

                </Typography>
                <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                    <DrawerItem />
                </Box>
                <ListMenu>
                    {itemList.map((item) => {
                        const { text } = item;
                        if (item.to == pathname) {
                            return (
                                <ListItem key={text}>
                                    <ListItemText primary={text} />
                                </ListItem>
                            )
                        } else if (item.text == 'People' || item.text == 'Lab') {
                            return (
                                <ListItem key={text}>
                                    <Badge badgeContent={'soon'} color="secondary">
                                        <ListItemText primary={text} />
                                    </Badge>
                                </ListItem>
                            )
                        } else {
                            return (
                                <ListItem key={text}>
                                    <ListItemButton component={Link} to={item.to} state={{ isFromApp: true }}
                                        sx={{
                                            color: '#fff',
                                            "&:hover": {
                                                backgroundColor: 'transparent',
                                                color: '#1e2a5a',
                                            }
                                        }}
                                    >
                                        <ListItemText primary={text} />
                                    </ListItemButton>
                                </ListItem>
                            )
                        }
                    })}
                </ListMenu>
            </StyledToolbar>
        </AppBar>
    )
}

export default Navbar;