import React, { useRef } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Card, CardActionArea, CardContent, Grid, Typography } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Title from '../components/Title';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

// FAQ part
const FAQs = [
  {
    "q": "What are web solutions?",
    "a": "Web solutions refer to a range of services and technologies designed to help businesses establish an online presence and achieve their goals. These solutions include website development, e-commerce platforms, content management systems (CMS), web hosting, domain registration, and more."
  },
  // {
  //   "q": "How can web solutions benefit my business?",
  //   "a": "Web solutions can benefit your business in numerous ways. They provide a platform to showcase your products or services, attract potential customers, improve brand visibility, increase online sales, streamline operations, and enhance customer experience. These solutions are tailored to meet your specific business objectives and target audience."
  // },
  {
    "q": "How long does it take to develop a website?",
    "a": "The time required for website development depends on various factors, such as the complexity of the website, desired features, and the availability of resources. Simple websites can be developed in a few weeks, while more complex projects may take several months."
  },
  {
    "q": "What is the cost of web development?",
    "a": "The cost of web development varies depending on the scope of the project. Factors such as design complexity, functionality, customization, and ongoing maintenance are taken into consideration."
  },
  {
    "q": "What is the difference between web design and web development?",
    "a": "Web design primarily focuses on the visual aspects of a website, such as layout, colors, and typography. On the other hand, web development involves the technical implementation of the design, including coding, programming, and database integration."
  },
  {
    "q": "Can I update and manage my website content?",
    "a": "Yes, most modern websites are built with content management systems (CMS) that allow you to easily update and manage your website content without any technical knowledge. A CMS provides a user-friendly interface for making changes to text, images, and other elements."
  },
  {
    "q": "Will my website be mobile-friendly?",
    "a": "Yes, it's crucial for websites to be mobile-friendly, considering the increasing number of users accessing the internet via mobile devices. Professional web developers ensure that websites are responsive and optimized for a seamless experience across various screen sizes."
  },
  {
    "q": "Can you help with website hosting and domain registration?",
    "a": "Yes, web development professionals can assist you in selecting a suitable hosting provider and registering a domain name for your website. They can guide you through the process and recommend reliable hosting options."
  },
  {
    "q": "Will my website be optimized for search engines (SEO)?",
    "a": "Web developers can implement basic on-page SEO practices during the development process, such as optimizing page titles, meta descriptions, and URL structures. However, for comprehensive SEO strategies, you may need to consult an SEO specialist or digital marketing agency."
  },
  {
    "q": "Can I integrate e-commerce functionality into my website?",
    "a": "Yes, web developers can integrate e-commerce functionality into your website, enabling you to sell products or services online. They can set up secure payment gateways, shopping carts, and inventory management systems."
  },
  {
    "q": "Do you provide ongoing website maintenance and support?",
    "a": "Many web development agencies offer ongoing website maintenance and support services. This includes regular backups, security updates, bug fixes, and technical support to ensure your website remains secure and functions smoothly."
  },
  {
    "q": "What are AI solutions?",
    "a": "AI solutions, or artificial intelligence solutions, leverage advanced technologies to provide intelligent automation, data analysis, and decision-making capabilities. These solutions utilize machine learning algorithms, natural language processing (NLP), computer vision, and other AI techniques to automate tasks, gain insights from data, and enhance business processes."
  },
  {
    "q": "How can AI solutions benefit my business?",
    "a": "AI solutions offer several benefits to businesses. They can automate repetitive tasks, improve efficiency and accuracy, enhance customer support through chatbots, provide personalized recommendations, optimize resource allocation, detect anomalies or patterns in data, and enable predictive analytics for better decision-making."
  },
  {
    "q": "Can I integrate AI into my existing business systems?",
    "a": "Yes, AI solutions can often be integrated into existing business systems and workflows. We can assess your current infrastructure and work with you to identify integration points and develop a seamless integration plan to leverage the power of AI in your operations."
  },
  {
    "q": "Are AI solutions expensive?",
    "a": "The cost of AI solutions can vary depending on various factors, including the complexity of the project, the extent of customization required, and the specific AI technologies utilized."
  },
  {
    "q": "Do I need technical expertise to implement AI solutions?",
    "a": "While technical expertise is beneficial, you don't necessarily need it to implement AI solutions. We can guide you through the process, from understanding your requirements to developing and deploying the AI solution. They can handle the technical aspects while ensuring a user-friendly experience for your team."
  },
  {
    "q": "Can AI solutions be scaled as my business grows?",
    "a": "Yes, AI solutions are designed to be scalable. As your business expands, AI solutions can be adapted to handle increased data volumes, user interactions, and complex scenarios. We can work with you to ensure that the solution scales effectively with your business needs."
  },
  {
    "q": "Are AI solutions secure and compliant?",
    "a": "We prioritize security and compliance. They implement robust security measures to protect data and adhere to relevant regulations, such as data privacy laws. They employ encryption, access controls, and follow best practices to ensure the confidentiality and integrity of your data."
  },
  {
    "q": "Do AI solutions require ongoing maintenance and support?",
    "a": "Yes, like any technology solution, AI solutions require ongoing maintenance and support. This includes monitoring the performance of the AI models, updating algorithms as needed, addressing potential issues, and providing technical support to ensure the smooth functioning of the AI solution."
  }
];

const FAQ = () => {
  const container = useRef();
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Grid ref={container}
        container
        spacing={0}
        px={{xs:0, sm:0, md:2}}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          py: 12.5,
          // background: 'whitesmoke'
        }}>
          {/* <span className='body-ground'></span> */}

      { /* FAQ SECTION */}
        <Grid item xs={12} sm={12} md={5} component="section" sx={{ marginTop: 2 }} 
          px={{xs:0, sm:0, md:2}}>
          <Title text={'FAQ'} textAlign={'center'} />
        </Grid>
        <Grid
          container
          spacing={0}
          px={{xs:0, sm:0, md:2}}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            py: 5,
            // px: 2
          }}>
          {FAQs.map((item, k) =>
              <Card 
              key={k} sx={{ maxWidth: '70rem', minHeight: '8.5rem', boxShadow: 0, mb: 1, borderRadius: 2 }}>
                <CardActionArea>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {item.q}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {item.a}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            )
          }
        </Grid>
      </Grid>
      </ThemeProvider>
  )
}

export default FAQ