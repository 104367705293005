import React from 'react';
import StudioStages from "./StudioStages";

const StudioSchema = () => {
    return (
        <>
            <StudioStages />
        </>
    );
}

export default StudioSchema;