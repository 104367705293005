import React, { useCallback, useEffect } from 'react';
import { Box, Button, styled, Typography, useTheme } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { loadFull } from 'tsparticles';
import Particles from 'react-tsparticles';

import { loadFull as loadFull2 } from 'tsparticles';
import Particles2 from 'react-tsparticles';
// import me_movie from '../assets/me_movie.mp4';

import "pathseg";
import { loadPolygonMaskPlugin } from 'tsparticles-plugin-polygon-mask';
import { loadPolygonMaskPlugin as loadPolygonMaskPlugin2 } from 'tsparticles-plugin-polygon-mask';

import imgsvg from '../assets/small.svg';
import imgsme from '../assets/sme.svg';

//img
// import headerImg from '../assets/test.png';

const Header = () => {
  const location = useLocation();
  const theme = useTheme();

  // Check if we use the mobile theme
  const isTabletDown = theme.breakpoints.values.md;

  // Check if the previous location is from another application page
  let isFromApp = location?.state && location.state.isFromApp;

  // But if it mobile, just skip the particals logo
  if (isTabletDown > window.innerWidth) {
    isFromApp = true;
  }

  const CustomBox = styled(Box)(({ theme }) => ({
    minHeight: '80vh',
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(2),
    paddingTop: theme.spacing(20),
    backgroundColor: '#090909',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center'
    }
  }));

  const BoxText = styled(Box)(({ theme }) => ({
    flex: '1',
    paddingLeft: theme.spacing(8),
    [theme.breakpoints.down('md')]: {
      flex: '2',
      textAlign: 'center',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },
    transition: '1s',
    transform: 'translateX(-1000px)'
  }));

  const particlesInit = useCallback(async (engine) => {
      // console.log(engine);
      await loadFull(engine);
      await loadPolygonMaskPlugin(engine);
  }, []);

  const particlesInit2 = useCallback(async (engine) => {
      // console.log(engine);
      await loadFull2(engine);
      await loadPolygonMaskPlugin2(engine);
  }, []);
      
  const handleScroll = () => {
    // console.log('window?.scrollY', window?.scrollY);
      if (window?.scrollY > 100 && window?.scrollY < 300) {
          // if(document.getElementById('tsparticles')?.children[0]){document.getElementById('tsparticles').children[0].style['opacity'] = '0.3';}
          // if(document.getElementById('another')?.children[0]) {document.getElementById('another').children[0].style['opacity'] = '0.3';}
      } else if (window?.scrollY > 300 && window?.scrollY < 840) {
          // if(document.getElementById('tsparticles').children[0]) {document.getElementById('tsparticles').children[0].style['opacity'] = '0';}
          // if(document.getElementById('another')?.children[0]) {document.getElementById('another').children[0].style['opacity'] = '0';}
          if(document.getElementById('command-terminale')?.style) {document.getElementById('command-terminale').style['transform'] = 'translateY(0rem)';}
          if(document.getElementById('custom_typography_blockquote_1')?.style) {document.getElementById('custom_typography_blockquote_1').style['opacity'] = '1';}
          // web_id
          if(document.getElementById('web_id')?.style) {document.getElementById('web_id').style['transform'] = 'translateX(0rem)';}
          if(document.getElementById('web_id')?.style) {document.getElementById('web_id').style['opacity'] = '1';}
          // ai_id
          if(document.getElementById('ai_id')?.style) {document.getElementById('ai_id').style['transform'] = 'translateX(10rem)';}
          if(document.getElementById('ai_id')?.style) {document.getElementById('ai_id').style['opacity'] = '0';}
      } else if (window?.scrollY > 840) {
          if(document.getElementById('custom_typography_blockquote_2')?.style){document.getElementById('custom_typography_blockquote_2').style['opacity'] = '1';}
          // ai_id
          if(document.getElementById('ai_id')?.style){document.getElementById('ai_id').style['transform'] = 'translateX(0rem)';}
          if(document.getElementById('ai_id')?.style){document.getElementById('ai_id').style['opacity'] = '1';}
          // web_id
          if(document.getElementById('web_id')?.style){document.getElementById('web_id').style['transform'] = 'translateX(-10rem)';}
          if(document.getElementById('web_id')?.style){document.getElementById('web_id').style['opacity'] = '0';}
      } else {
          // web_id
          if(document.getElementById('web_id')?.style){document.getElementById('web_id').style['transform'] = 'translateX(-10rem)';}
          if(document.getElementById('web_id')?.style){document.getElementById('web_id').style['opacity'] = '0';}
          // if(document.getElementById('tsparticles')?.children[0]){document.getElementById('tsparticles').children[0].style['opacity'] = '1';}
          // if(document.getElementById('another')?.children[0]){document.getElementById('another').children[0].style['opacity'] = '1';}
      }
    }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    if (isFromApp) {
      if(document.getElementById('metastability_inc_video')?.style) {document.getElementById('metastability_inc_video').style['opacity'] = '0.8';document.getElementById('metastability_inc_video')?.play()}
      if(document.getElementById('box_text_id')?.style){document.getElementById('box_text_id').style['transform'] = 'translateX(0px)';}
    } else {
      setTimeout(() => {
          if(document.getElementById('box_text_id')?.style){document.getElementById('box_text_id').style['transform'] = 'translateX(0px)';}
      }, 3500);
      setTimeout(() => {
        if(document.getElementById('tsparticles')?.children[0]){document.getElementById('tsparticles').children[0].style['transform'] = 'translateX(0rem)';}
        if(document.getElementById('another')?.children[0]){document.getElementById('another').children[0].style['transform'] = 'translateX(0rem)';}
      }, 2500);
      setTimeout(() => {
        if(document.getElementById('tsparticles')?.children[0]){document.getElementById('tsparticles').children[0].style['opacity'] = '0';}
        if(document.getElementById('another')?.children[0]){document.getElementById('another').children[0].style['opacity'] = '0';}
        if(document.getElementById('metastability_inc_video')?.style) {document.getElementById('metastability_inc_video').style['opacity'] = '0.8';document.getElementById('metastability_inc_video')?.play()}
      }, 5000);
      setTimeout(() => {
        if(document.getElementById('tsparticles')?.children[0]){document.getElementById('tsparticles').remove();}
        if(document.getElementById('another')?.children[0]){document.getElementById('another').remove();}
      }, 8000);
    }
  }, [])

  return (
    <CustomBox component="header" >
      {/*  Box text  */}
    <video autoPlay="true" muted preload="auto" id="metastability_inc_video" loop="true" playsInline="true" width="100%" className="video_for">
      <source src="https://openmetastability.s3.amazonaws.com/me_movie.mp4" type="video/mp4"/>
    </video>
      <BoxText component="section" id='box_text_id' sx={{ zIndex: 1001 }}>
        <Typography
          variant="h2"
          component="h1"
          sx={{
            fontWeight: 700,
            color: '#fff'
          }}>
          IT Solutions for Business 
        </Typography>

        <Typography
          variant="p"
          component="p"
          sx={{
            py: 3,
            lineHeight: 1.6,
            color: '#fff'
          }}>
          Creating new systems and modifying existing ones for provide <code>web</code> and software solutions to large and small businesses.
        </Typography>

        <Box>
          <Button
            component={Link}
            to={'/about'}
            variant="outlined"
            sx={{
              px: 4,
              py: 1,
              fontSize: '0.9rem',
              textTransform: 'capitalize',
              borderRadius: 0,
              color: '#fff',
              zIndex: 1001,
              backgroundColor: 'transparent',
              borderColor: '#fff',
              '&&:hover': {
                color: '#343a55',
                borderColor: '#343a55'
              },
              '&&:focus': {
                color: '#343a55',
                borderColor: '#343a55'
              }
            }}>
            explore
          </Button>
          <Button
            component={Link}
            to={'/contact'}
            variant="contained"
            className='glow-on-hover'
            sx={{
              ml: 2,
              px: 4,
              py: 1,
              fontSize: '0.9rem',
              textTransform: 'capitalize',
              borderRadius: '10px',
              zIndex: 1001,
              borderColor: '#14192d',
              color: '#fff',
              backgroundColor: '#14192d',
              '&&:hover': {
                backgroundColor: '#343a55'
              },
              '&&:focus': {
                backgroundColor: '#343a55'
              }
            }}
            >
            Get started
          </Button>
        </Box>
      </BoxText>

      <Box
        sx={(theme) => ({
          [theme.breakpoints.down('md')]: {
            flex: '1',
            paddingTop: '30px',
            alignSelf: 'center',
            display: 'none'
          },
          [theme.breakpoints.up('md')]: {
            flex: '2',
            alignSelf: 'flex-end'
          }
        })}>
        {isFromApp ? '' : <>
        <Particles
          style={{
            transition: '3s',
            transform: 'translateX(-12rem)',
            opacity: 1
          }}
          init={particlesInit}
          options={{
            detectRetina: false,
            fpsLimit: 30,
            interactivity: {
              detectsOn: 'canvas',
              events: {
                onHover: {
                  enable: true,
                  mode: 'bubble'
                },
                resize: true
              },
              modes: {
                bubble: {
                  distance: 40,
                  duration: 2,
                  opacity: 8,
                  size: 6,
                  speed: 0.5
                }
              }
            },
            particles: {
              color: {
                value: '#23a6d5',
                animation: {
                  enable: true,
                  speed: 1,
                  sync: true
                }
              },
              lineLinked: {
                blink: false,
                color: 'random',
                consent: false,
                distance: 8,
                enable: true,
                opacity: 1.0,
                width: 0.9
              },
              move: {
                attract: {
                  enable: false,
                  rotate: {
                    x: 600,
                    y: 1200
                  }
                },
                bounce: true,
                direction: 'topRight',
                enable: true,
                outMode: 'bounce',
                random: false,
                speed: 0.01,
                straight: true
              },
              number: {
                density: {
                  enable: false,
                  area: 200
                },
                limit: 0,
                value: 30
              },
              opacity: {
                animation: {
                  enable: true,
                  minimumValue: 0.5,
                  speed: 0.5,
                  sync: false
                },
                random: false,
                value: 1
              },
              shape: {
                type: 'circle'
              },
              size: {
                animation: {
                  enable: true,
                  minimumValue: 0.5,
                  speed: 0.5,
                  sync: true
                },
                random: true,
                value: 1
              }
            },
            polygon: {
              draw: {
                enable: true,
                lineColor: 'rgb(35, 166, 213, 0.0)',
                lineWidth: 1
              },
              move: {
                radius: 2
              },
              inlineArrangement: 'equidistant',
              scale: 0.8,
              type: 'inline',
              url: imgsvg
            }
          }}
        />

        <Particles2
          style={{
            transition: '3s',
            transform: 'translateX(-12rem)',
            opacity: 1
          }}
          id='another'
          init={particlesInit2}
          options={{
            detectRetina: false,
            fpsLimit: 60,
            interactivity: {
              detectsOn: 'canvas',
              events: {
                onHover: {
                  enable: true,
                  mode: 'bubble'
                },
                resize: true
              },
              modes: {
                bubble: {
                  distance: 30,
                  duration: 2,
                  opacity: 8,
                  size: 6,
                  speed: 0.5
                }
              }
            },
            particles: {
              color: {
                value: '#23a6d5',
                animation: {
                  enable: true,
                  speed: 1,
                  sync: true
                }
              },
              lineLinked: {
                blink: false,
                color: 'random',
                consent: false,
                distance: 40,
                enable: true,
                opacity: 1.0,
                width: 0.5,
                // triangles: {
                //   enable: true,
                //   color: "#ffffff08",
                //   opacity: 0.01
                // }
              },
              move: {
                attract: {
                  enable: false,
                  rotate: {
                    x: 600,
                    y: 1200
                  }
                },
                bounce: false,
                direction: 'topRight',
                enable: true,
                outMode: 'bounce',
                random: false,
                speed: 0.1,
                straight: true
              },
              number: {
                density: {
                  enable: false,
                  area: 200
                },
                limit: 0,
                value: 1
              },
              opacity: {
                animation: {
                  enable: true,
                  minimumValue: 0.5,
                  speed: 0.5,
                  sync: false
                },
                random: false,
                value: 1
              },
              shape: {
                type: 'circle'
              },
              size: {
                animation: {
                  enable: true,
                  minimumValue: 0.5,
                  speed: 0.5,
                  sync: false
                },
                random: true,
                value: 1
              }
            },
            polygon: {
              draw: {
                enable: true,
                lineColor: 'rgb(35, 166, 213, 0.0)',
                lineWidth: 1
              },
              move: {
                radius: 2
              },
              inlineArrangement: 'equidistant',
              scale: 0.8,
              type: 'inline',
              url: imgsme
            }
          }}
        />
        </>}
        {/* <img
                src={headerImg}
                alt="headerImg"
                style={{ 
                    width: "100%", 
                    marginBottom: -15,
                }}
                /> */}
      </Box>
    </CustomBox>
  );
};

export default Header;
