import React, { useEffect, useRef, useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Grid, Button } from '@mui/material';
// import { Link } from 'react-router-dom';
// import { IconButton, Card, CardContent } from '@mui/material';
// icons
// import SportsGymnasticsIcon from '@mui/icons-material/SportsGymnastics';
// import LocalParkingIcon from '@mui/icons-material/LocalParking';
// import ArrowCircleRightRoundedIcon from '@mui/icons-material/ArrowCircleRightRounded';
// import FastfoodOutlinedIcon from '@mui/icons-material/FastfoodOutlined';
// import PoolOutlinedIcon from '@mui/icons-material/PoolOutlined';
// import WifiPasswordIcon from '@mui/icons-material/WifiPassword';
// components
import Title from './Title';
import Gallery from './Gallery';
import ExploreList from './Explore/ExploreList';
import CssBaseline from '@mui/material/CssBaseline';
import { Link } from 'react-router-dom'

import me_ai from '../assets/me_ai.gif';
import StudioSchema from './Explore/StudioSchema';
import LazyLoadedVideo from './Explore/LazyLoadedVideo';
import LazyLoadedIframe from './Explore/LazyLoadedIframe';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

// Gallery Items Data
const itemsData = [
  {
      id: 0,
      type: 'SIMPLE_COMPONENT',
      name: 'Landing',
      description: 'Not just a pretty page to present your business, but a path to achieving your goals.',
      phone: <LazyLoadedIframe 
      src="https://openmetastability.s3.amazonaws.com/landing_phone.html"
        options={{
          className: "iframe_phone",
          title: "Example",
          sandbox: "allow-scripts allow-same-origin"
        }}
      />,
    // <iframe className="iframe_phone" src="https://openmetastability.s3.amazonaws.com/landing_phone.html" title="Example" sandbox="allow-scripts allow-same-origin"></iframe>,
      tablet: <LazyLoadedIframe 
      src="https://openmetastability.s3.amazonaws.com/landing_tablet.html"
        options={{
          className: "iframe_tablet",
          title: "Example",
          sandbox: "allow-scripts allow-same-origin"
        }}
      />,
      // <iframe className="iframe_tablet" src="https://openmetastability.s3.amazonaws.com/landing_tablet.html" title="Example" sandbox="allow-scripts allow-same-origin"></iframe>,
      tablet_width: '1300px'
  },
  {
      id: 1,
      type: 'SIMPLE_COMPONENT',
      name: 'Custom',
      description: 'Ideate, develop, and launch tailored digital solutions that get your business closer to its goals.',
      phone: <LazyLoadedIframe 
        src="https://openmetastability.s3.amazonaws.com/custom_phone.html"
        options={{
          className: "iframe_phone",
          title: "Example",
          sandbox: "allow-same-origin"
        }}
      />,
    //  <iframe className="iframe_phone" src="https://openmetastability.s3.amazonaws.com/custom_phone.html" title="Example" sandbox="allow-same-origin"></iframe>,
      tablet: <LazyLoadedIframe 
      src="https://openmetastability.s3.amazonaws.com/custom_tablet.html"
        options={{
          className: "iframe_tablet",
          title: "Example",
          sandbox: "allow-same-origin"
        }}
      />,
      // <iframe className="iframe_tablet" src="https://openmetastability.s3.amazonaws.com/custom_tablet.html" title="Example" sandbox="allow-same-origin"></iframe>,
      tablet_width: '1350px'
  },
  {
      id: 2,
      type: 'SIMPLE_COMPONENT',
      name: 'eCommerce',
      description: 'Not just a store for your things, but a sales center for your ideas and products.',
      phone: <LazyLoadedIframe 
        src="https://openmetastability.s3.amazonaws.com/eCommerce_phone.html"
        options={{
          className: "iframe_phone",
          title: "Example",
          sandbox: "allow-same-origin"
        }}
      />,
      // <iframe className="iframe_phone" src="https://openmetastability.s3.amazonaws.com/eCommerce_phone.html" title="Example" sandbox="allow-same-origin"></iframe>,
      tablet: <LazyLoadedIframe 
        src="https://openmetastability.s3.amazonaws.com/eCommerce_tablet.html"
        options={{
          className: "iframe_tablet",
          title: "Example",
          sandbox: "allow-same-origin"
        }}
      />,
      // <iframe className="iframe_tablet" src="https://openmetastability.s3.amazonaws.com/eCommerce_tablet.html" title="Example" sandbox="allow-same-origin"></iframe>,
      tablet_width: '1350px'
  },
  {
      id: 3,
      type: 'SIMPLE_COMPONENT',
      name: 'Mobile Application',
      description: 'Implement mobile solutions for your purposes. You can also combine everything with your own web system!',
      phone:  <LazyLoadedVideo src="https://openmetastability.s3.amazonaws.com/ios_development.mp4" options={{
          autoPlay: "true",
          muted: "true", 
          preload: "auto",
          id: "ios_development",
          loop: "true",
          playsInline: "true",
          width: "100%",
          className: "video_for_example iframe_phone",
          style: { borderRadius: '66px' }
        }
      } />,
      // <video autoPlay="true" muted preload="auto" id="ios_development" loop="true" playsInline="true" width="100%" className="video_for_example iframe_phone" style={{ borderRadius: '66px' }}>
      //           <source src="https://openmetastability.s3.amazonaws.com/ios_development.mp4" type="video/mp4"/>
      //         </video>,
      tablet: <LazyLoadedVideo src="https://openmetastability.s3.amazonaws.com/ios_development_vs.mp4" options={{
        autoPlay: "true",
        muted: "true", 
        preload: "auto",
        id: "ios_development",
        loop: "true",
        playsInline: "true",
        width: "100%",
        className: "video_for_example_vs iframe_tablet",
      }} />,
      // <video autoPlay="true" muted preload="auto" id="ios_development" loop="true" playsInline="true" width="100%" className="video_for_example_vs iframe_tablet">
      //           <source src="https://openmetastability.s3.amazonaws.com/ios_development_vs.mp4" type="video/mp4"/>
      //         </video>,
      tablet_width: '1650px'
  },
  {
      id: 4,
      type: 'SIMPLE_COMPONENT',
      name: 'AI Solution',
      description: 'Automate your business logic. Outsource day-to-day, repetitive tasks to an automated assistant pre-trained for your needs.',
      phone:  <div style={{ fontSize: '30pt', fontFamily: 'Roboto, sansSerif', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <img src={me_ai} alt='me' style={{ width: '50%', filter: 'brightness(3)', marginBottom: '50px' }}/>
                {/* <div className='typing-text'>How can I assist you today?</div> */}
              </div>,
      tablet: <div style={{ fontSize: '30pt', fontFamily: 'Roboto, sansSerif', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <img src={me_ai} alt='me' style={{ width: '30%', filter: 'brightness(3)', marginBottom: '50px' }}/>
                {/* <div className='typing-text' style={{ width: '680px' }}>Hello! How i can help you today?</div> */}
              </div>,
      tablet_width: '1350px'
  },
];

// It's for Explore List
const exploreList = [
  {
      id: 0,
      h: "Landing",
      text: "Not just a pretty page to present your business, but a path to achieving your goals.",
      boxShadow: 'rgba(32, 117, 244, 0.58) 0px -12px 2.5em 17px'
  },
  {
      id: 1,
      h: "Custom",
      text: [`Ideate, develop, and launch tailored digital solutions that get your business closer to its goals.`,`Let's implement what is necessary to grow and optimize your business and business logic, as well as achieve your aim.`, `Let it be the same online store, but with payment in bitcoins, or investment platform, or a system for registering and tracking goods around the world, or a car sharing application. Integrate everithing to web!`],
      type: 'Popular',
      boxShadow: 'rgba(193, 99, 157, 0.56) 0px -12px 2.5em 17px'
  },
  {
      id: 2,
      h: "eCommerce",
      text: "Not just a store for your things, but a sales center for your ideas and products.",
      boxShadow: 'rgba(32, 224, 244, 0.58) 0px -12px 2.5em 17px'
  },
  {
      id: 3,
      h: "Mobile Application",
      text: "Implement mobile solutions for your purposes. You can also combine everything with your own web system!",
      boxShadow: 'rgba(90, 77, 255, 0.51) 0px -12px 2.5em 12px'
  },
  {
      id: 4,
      h: "AI Solution",
      text: "Automate your business logic. Outsource day-to-day, repetitive tasks to an automated assistant pre-trained for your needs.",
      boxShadow: 'rgba(32, 117, 244, 0.58) 0px -12px 2.5em 17px'
  }
];

const Content = () => {

  // Functional for Gallery Carousel 
  const [currentIndex, setCurrentIndex] = useState(0);
  // const goToNext = () => {
  //     setCurrentIndex((prevIndex) => (prevIndex + 1) % itemsData.length);
  // };
  // const goToPrev = () => {
  //     setCurrentIndex((prevIndex) => (prevIndex - 1 + itemsData.length) % itemsData.length);
  // };
  // useEffect(() => {
  //   // Auto-scroll interval
  //   const interval = setInterval(() => {
  //       setCurrentIndex((prevIndex) => (prevIndex + 1) % itemsData.length);
  //   }, 10000); // Auto-scroll every 3 seconds
  //   return () => clearInterval(interval);
  // }, []);

  const exploreListRef = useRef();
  const exploreListRef_text = useRef();
  const exploreListRef_phone = useRef();
  const exploreListRef_tablet = useRef();

  const setCurrentIndexFn = (id) => {

    exploreListRef.current.style.filter = 'blur(5px)';

    exploreListRef_text.current.style.filter = 'blur(10px)';
    exploreListRef_text.current.style.opacity = '0';

    exploreListRef_phone.current.style.opacity = '0';
    exploreListRef_phone.current.style.filter = 'blur(20px)';
    
    exploreListRef_tablet.current.style.opacity = '0';
    exploreListRef_tablet.current.style.filter = 'blur(20px)';

    setTimeout(() => {

      setCurrentIndex(id);
      exploreListRef.current.style.filter = 'blur(0px)';
      setTimeout(() => {

        exploreListRef_text.current.style.filter = 'blur(0px)';
        exploreListRef_text.current.style.opacity = '1';

        exploreListRef_phone.current.style.opacity = '1';
        exploreListRef_phone.current.style.filter = 'blur(0px)';

        exploreListRef_tablet.current.style.opacity = '1';
        exploreListRef_tablet.current.style.filter = 'blur(0px)';
      }, 300);
    }, 300);
  };

  useEffect(() => {
    setTimeout(() => {

      exploreListRef_text.current.style.filter = 'blur(0px)';
      exploreListRef_text.current.style.opacity = '1';

      exploreListRef_phone.current.style.opacity = '1';
      exploreListRef_phone.current.style.filter = 'blur(0px)';

      exploreListRef_tablet.current.style.opacity = '1';
      exploreListRef_tablet.current.style.filter = 'blur(0px)';
    }, 300);
  }, []);

  // It's FAQ Section
  const container = useRef();

  // const [simple, setSimple] = useState(false);
  const simple = false;

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Grid ref={container}
        container
        spacing={0}
        px={{xs:0, sm:0, md:2}}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          py: 12.5,
          // background: 'whitesmoke'
        }}>
          {/* <span className='body-ground'></span> */}
        <Grid item xs={12} sm={12} md={5} component="section" sx={{ marginBottom: 4 }}>
          <Title text={'What we are offering?'} textAlign={'center'} />
        </Grid>

        {/* Explore list with solutions what we are offering */}
        <ExploreList
          exploreList={exploreList} 
          setCurrentIndexFn={setCurrentIndexFn} 
          currentIndex={currentIndex}
          simple={simple}
          />

        {/* Gallery of Items*/}
        {!simple  &&
        (<Gallery
          exploreListRef={exploreListRef}
          exploreListRef_text={exploreListRef_text}
          exploreListRef_phone={exploreListRef_phone}
          exploreListRef_tablet={exploreListRef_tablet}
          itemsData={itemsData} 
          currentIndex={currentIndex}/>)
        }

        { /* Studio Schema */}
        <Grid item xs={12} sm={12} md={5} component="section" sx={{ marginTop: 15, marginBottom: 5 }}>
          <Title text={'Our Service'} textAlign={'center'} />
        </Grid>
        <StudioSchema />

        {/* GET STARTED*/}
        <Grid xs={12} sm={12} md={12}>
          <Button
            component={Link}
            to={'/contact'}
            variant="contained"
            className='glow-on-hover'
            sx={{
              background: 'transparent',
              px: 4,
              py: 1,
              transform: 'scale(1.5) !important',
              mt: 12,
              mb: 15,
              fontSize: '0.9rem',
              textTransform: 'capitalize',
              borderRadius: '10px',
              zIndex: 1001,
              borderColor: '#14192d',
              color: '#fff',
              backgroundColor: 'transparent !important',
              '&&:hover': {
                backgroundColor: '#343a55'
              },
              '&&:focus': {
                backgroundColor: '#343a55'
              },
              '&&:before': {
                opacity: '1 !important'
              },
              '&&:after': {
                // background: 'transparent !important'
              }
            }}
            >
            Get started
          </Button>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default Content;