import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';

const styles = {
  tr: {
    '&:hover': {
    }
  }
};

const GalleryCard = ({data, classes, setCurrentIndexFn, currentIndex}) => {
    // console.log('data', data);
  return (
    <Box 
      className={`text-shadow ${classes.tr} gallery-box-custom`} 
      sx={{ 
        margin: '8px 12px', 
        cursor: 'pointer'
      }}
      onClick={() => setCurrentIndexFn(data.id)}>
        <Typography 
          variant="h5" 
          component="div" 
          sx={{ 
                transition: '0.25s ease-in-out',
                fontSize: currentIndex == data.id ? '1.5rem' : '1.25rem', 
                color: currentIndex == data.id ? '#fff' : '#ffffff9e',
                px: currentIndex == data.id ? 1 : 0,
                '&:hover': {
                  color: currentIndex == data.id ? '#ffffff9e' : '#a25e2d'
                }
              }}>
          {data.h}
        </Typography>
        {data?.type &&
                <Typography sx={{ 
                    fontSize: 9, 
                    position: 'absolute', 
                    ml: 8,
                    mt: -5,
                    paddingLeft: 1, 
                    paddingRight: 1,
                    opacity: currentIndex == data.id ? 1 : 0,
                    filter: currentIndex == data.id ? 'blur(0px)' : 'blur(10px)',
                    transition: '1s ease-in-out'
                  }} color="#ffc14a">
                  {data?.type}
                </Typography>
            }
        <Box sx={{
          boxShadow: currentIndex == data.id ? data.boxShadow : '0px 0px 0.5em 1px rgb(27, 29, 32)'
          }}></Box>
    </Box>
  );
}

export default withStyles(styles)(GalleryCard)