import React from 'react'
// mui
import { 
    Typography,
    Box,
    Stack,
} from "@mui/material";
// carousel
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import { Carousel } from "react-responsive-carousel";
// components
// import Title from './Title'
// import Paragraph from './Paragraph'
import LandingPage from './Gallery/LandingPage';


const Gallery = ({ itemsData, currentIndex = 1, exploreListRef, exploreListRef_text, exploreListRef_phone, exploreListRef_tablet }) => {

    const currentItem = itemsData.filter((({id}) => id == currentIndex));

    const renderSlides = currentItem?.map((item) => (
        <div key={item.id} style={{ height: '450px', display: 'flex', justifyContent: 'center', alignItems: 'center', gridTemplateColumns: '250px' }}>
            {item.type == 'SIMPLE_COMPONENT' && <LandingPage 
        exploreListRef_phone={exploreListRef_phone}
        exploreListRef_tablet={exploreListRef_tablet}
         phone={item.phone} tablet={item.tablet} tablet_width={item.tablet_width} />}
        </div>
    ));

    return (
        <Stack
        direction='column'
        justifyContent= 'center'
        alignItems= 'center'
        sx={{
            py: 0,
            px: 0,
            display: { xs: 'flex'},
            transition: 'all 0.1s ease-out',

        }}
        ref={exploreListRef}
        >
            <Box sx={{ width: '50%',height:'40%',position:'absolute',background: 'linear-gradient(132deg, rgba(159, 136, 136, 0.64) 0.00%, rgba(0, 143, 186, 0.44) 50.00%, rgba(162, 255, 149, 0.16) 95.00%)',filter: 'blur(100px)', borderRadius: '100px', opacity: '0.4' }}></Box>
            <Box 
                className='custom-gallery-box'
                sx={{
                width: '100%',
                transition: '0.3s ease-in-out'
                }}>
                {renderSlides}
                <Typography
                variant='h5'
                component='h4'
                align='center'
                sx={{
                    paddingTop: 0,
                    paddingLeft: 2,
                    paddingRight: 2,
                    color: '#ffffffe8',
                    transition: 'all 0.3s ease-out',
                    filter: 'blur(10px)',
                    opacity: '0'
                }}
                ref={exploreListRef_text}
                >
                    {currentItem[0].description}
                </Typography>
            </Box>
        </Stack>
    )
}

export default Gallery