import React from 'react';
import { Avatar } from '@mui/material';
import { Assignment, MonetizationOn, Description, Build, CheckCircle } from '@mui/icons-material';

const StudioStages = () => {
    const steps = [
        { label: 'Place your order', icon: <Assignment fontSize="large" />, description: 'Send a detailed project proposal. You can fill/send/attach your inquiry.' },
        { label: 'Cost and development time calculation', icon: <MonetizationOn fontSize="large" />, description: 'Getting your project costs and implementation timeline.' },
        { label: 'Order confirmation', icon: <Description fontSize="large" />, description: 'Drafting and finalizing the contract agreement. Signing contract. Submitting your down payment to start the project.' },
        { label: 'Implementing process', icon: <Build fontSize="large" />, description: 'Starting working on the project and bring the idea to life.' },
        { label: 'Getting Product', icon: <CheckCircle fontSize="large" />, description: 'Delivering the final product and ensuring client satisfaction.' },
    ];

    return (
        <>
            <div className="timeline">
            <div className="gradient_under_schema"></div>
              <div className="outer">
                {steps?.map((step, key) => {
                    return(
                        <div key={key} className="timeline_card">
                            <div className="timeline_info">
                                <Avatar className="timeline_title_icon">{step.icon}</Avatar>
                                <h3 className="timeline_title">{step.label}</h3>
                                <p className="timeline_p">{step.description}</p>
                            </div>
                        </div>
                    )
                })}
              </div>
            </div>
        </>
    );
}

export default StudioStages;