import React from "react";

const LandingPage = ({ phone, tablet, tablet_width = '1200px', exploreListRef_phone, exploreListRef_tablet }) => {
    return (
        <>
            {/* Phone */}
            <div className="container_phone">
              <div className="phone">
                <div className="toggleButton Button"></div>
                <div className="volumeButtonUp Button"></div>
                <div className="volumeButtonDown Button"></div>
                <div className="powerButton Button"></div>
                <div className="glares">
                  <div className="horizontalGlare">
                    <div className="glare1"></div>
                  </div>
                  <div className="verticalGlare">
                    <div className="glare2"></div>
                  </div>
                </div>
                <div className="screen_phone" ref={exploreListRef_phone} style={{ transition: 'all 0.3s ease-out', filter: 'blur(20px)', opacity: '0' }}>
                  <div className="signal">
                    {/* METASTABILITY */}
                    {/* <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div> */}
                  </div>
                  <div className="strip">
                    <div className="circleHolder"><div className="circle1"></div><div className="circle2"></div>  
                    </div>
                    <div className="stripItems">
                      <div className="camera"></div>
                      <div className="camera second"></div>
                      <div className="speaker"></div>
                      <div className="prox ir"></div>
                      <div className="prox"></div>
                    </div>
                  </div>
                  <div className="batteryBar">
                    {/* <div className="batter"><div className="e"></div></div>100% */}
                  </div>
                  {phone}
                </div>
              </div>
            </div>
            {/* Tablet */}
            <div className="container_tablet">
              <div className="tablet" style={{width: tablet_width}}>
                <div className="toggleButton Button"></div>
                <div className="volumeButtonUp Button"></div>
                <div className="volumeButtonDown Button"></div>
                <div className="powerButton Button"></div>
                <div className="glares_tablet">
                  <div className="horizontalGlare">
                    <div className="glare1_tablet"></div>
                  </div>
                  <div className="verticalGlare_tablet">
                    <div className="glare2_tablet"></div>
                  </div>
                </div>
                <div className="screen_tablet" ref={exploreListRef_tablet} style={{ transition: 'all 0.3s ease-out', filter: 'blur(20px)', opacity: '0' }}>
                  <div className="signal">
                    {/* METASTABILITY */}
                    {/* <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div> */}
                  </div>
                  <div className="strip">
                    <div className="circleHolder"><div className="circle1"></div><div className="circle2"></div>  
                    </div>
                    <div className="stripItems">
                      <div className="prox ir"></div>
                      <div className="prox"></div>
                      <div className="camera"></div>
                      <div className="camera second"></div>
                    </div>
                  </div>
                  <div className="batteryBar">
                    {/* <div className="batter"><div className="e"></div></div>100% */}
                  </div>
                  {tablet}
                </div>
              </div>
            </div>
        </>
    )
};

export default LandingPage;