import React from 'react'
import { 
  Box, 
  Stack, 
  styled, 
  Typography,
} from '@mui/material'
import Link from '@mui/material/Link';
import FooterTitle from './FooterTitle'
import FooterLink from './FooterLink'
import { Instagram, LinkedIn, Twitter } from '@mui/icons-material';

const Footer = () => {

  const StackColumn = styled(Stack) (() => ({
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flex: 1,
    gap: 8,
    textAlign: 'center',
  }));

  const BoxRow = styled(Box) (({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#ededed',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: 30,
    }
  }));

  return (
    
    <BoxRow 
    component = 'footer'
    sx={{
      py: 4,
      px: 2,
    }}
    >
      <StackColumn>
        <FooterTitle text={'address'} />
        <FooterLink 
         text={'8226 Day St'} 
         />
        <FooterLink 
        text={'Sunland, CA 91040'} 
        />
        <FooterLink 
        text={''} 
        />
      </StackColumn>
      
      {/* <StackColumn>
        <FooterTitle text={'our services'} />
        {/* <FooterLink text={'buy  '} />
        <FooterLink text={'sell  '} />
        <FooterLink text={'rent  '} />
        <FooterLink text={'build  '} /> */}
      {/* </StackColumn> */} 
      <StackColumn>
        <FooterTitle text={''} />
        <FooterLink text={'reporting'} />
        <FooterLink text={'get in touch'} />
        <FooterLink text={'management'} />
      </StackColumn>

      <StackColumn>
        <FooterTitle text={'Metastability'} />
        <Stack 
        direction='row' 
        width= '70px'
        maxWidth='100%'
        justifyContent='space-between'
        >

          <Link href="https://twitter.com/_metastability" variant="body2" 
          sx={{
            color: '#414141',
            "&:hover": {
              color: '#1c2859',
            },
            zIndex: 1001
          }}
          >
            <Twitter />
          </Link> 

          <Link href="https://www.instagram.com/_metastability"variant="body2" 
          sx={{
            color: '#414141',
            "&:hover": {
              color: '#1c2859',
            },
            zIndex: 1001
          }}
          >
            <Instagram />
          </Link> 
          <Link href="https://www.linkedin.com/company/metastability" variant="body2" 
          sx={{
            color: '#414141',
            "&:hover": {
              color: '#1c2859',
            },
            zIndex: 1001
          }}
          >
            <LinkedIn />  
          </Link> 
          {/* <Link href="#"variant="body2" 
          sx={{
            color: '#414141',
            "&:hover": {
              color: '#1c2859',
            }
          }}
          >
            <FacebookIcon />
          </Link>  */}
        </Stack>
        <Typography 
        variant='caption'
        component='p' 
        sx={{ color: 'black' }}
        >
          &copy; 2024 Metastability Inc.
        </Typography>
      </StackColumn>
    </BoxRow>
  )
}

export default Footer