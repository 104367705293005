import React from'react';
//rotas
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { StylesProvider } from '@mui/styles';
//pages
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
//componentes
import Navbar from './components/Navbar';
import Footer from './components/Footer/Footer';
import './app.css';
import People from './pages/People';
import Lab from './pages/Lab';
import ScrollToTop from './components/ScrollToTop';
import FAQ from './pages/FAQ';

function App() {
 
  const ground = []; // Initialize array
  for (var i = 0 ; i < 15; i++) {
      ground[i] = []; // Initialize inner array
      for (var j = 0; j < 9; j++) { // i++ needs to be j++
          ground[i][j] = (Math.random() * 5 | 0) + 6;
      }
  }

  const makeid = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  const grounds = ground.map((ar) => {
      return ar.map((param) => makeid(param));
    }).join().replaceAll(',', '');

  // console.log('%c' + grounds, 'background: black; color: blue;');
  console.log(`%c ${grounds}            
  ███╗░░░███╗███████╗████████╗░█████╗░░██████╗████████╗░█████╗░██████╗░██╗██╗░░░░░██╗████████╗██╗░░░██╗
  ████╗░████║██╔════╝╚══██╔══╝██╔══██╗██╔════╝╚══██╔══╝██╔══██╗██╔══██╗██║██║░░░░░██║╚══██╔══╝╚██╗░██╔╝
  ██╔████╔██║█████╗░░░░░██║░░░███████║╚█████╗░░░░██║░░░███████║██████╦╝██║██║░░░░░██║░░░██║░░░░╚████╔╝░
  ██║╚██╔╝██║██╔══╝░░░░░██║░░░██╔══██║░╚═══██╗░░░██║░░░██╔══██║██╔══██╗██║██║░░░░░██║░░░██║░░░░░╚██╔╝░░
  ██║░╚═╝░██║███████╗░░░██║░░░██║░░██║██████╔╝░░░██║░░░██║░░██║██████╦╝██║███████╗██║░░░██║░░░░░░██║░░░
  ╚═╝░░░░░╚═╝╚══════╝░░░╚═╝░░░╚═╝░░╚═╝╚═════╝░░░░╚═╝░░░╚═╝░░╚═╝╚═════╝░╚═╝╚══════╝╚═╝░░░╚═╝░░░░░░╚═╝░░░            
  ${grounds}
  `, 'background: transparent; color: blue;');
  // console.log('%c' + grounds, 'background: black; color: blue;');
  
  document.getElementsByTagName('body')[0].style['background'] = '#090909';
  document.getElementsByTagName('body')[0].style['overflow-x'] = 'hidden';
  return (
    <>
      <StylesProvider injectFirst>
        <BrowserRouter>
          <Navbar />
          <ScrollToTop />
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/about' element={<About />} />
            <Route path='/people' element={<People />} />
            <Route path='/lab' element={<Lab />} />
            <Route path='/faq' element={<FAQ />} />
            <Route path='/contact' element={<Contact />} />
          </Routes>
        </BrowserRouter>
        <Footer />
      </StylesProvider>
    </>
  );
}
export default App;