import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import OutlinedCard from './ItemCard';
import GalleryCard from './GalleryCard';

export default function ExploreList({ exploreList = [], setCurrentIndexFn, currentIndex, simple }) {
  if (simple) {
    return (
      <Box sx={{ flexGrow: 1, padding: 4 }}>
        <Grid container spacing={0} sx={{
            alignItems: 'center',
            justifyContent: 'center'
        }}>
          {exploreList?.map((data, key) => 
              <OutlinedCard 
                key={key} 
                data={data} 
                currentIndex={currentIndex}
                setCurrentIndexFn={setCurrentIndexFn}/>
            )}
        </Grid>
      </Box>
    );
  } else {
    return (
      <Box sx={{ flexGrow: 1, padding: 0 }}>
        <Grid container spacing={0} sx={{
            alignItems: 'center',
            justifyContent: 'center'
        }}>
          {exploreList?.map((data, key) => 
              <GalleryCard 
                key={key} 
                data={data} 
                currentIndex={currentIndex}
                setCurrentIndexFn={setCurrentIndexFn}
                />
          )}
        </Grid>
      </Box>
    );
  }
}