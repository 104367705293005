import React, { useState } from 'react'
import {  
  styled, 
  useTheme,
  Drawer,
  Divider,
  List,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
// rotas
import { Link, useLocation } from 'react-router-dom';
// icons
import EmailIcon from '@mui/icons-material/Email';
import HomeIcon from '@mui/icons-material/Home';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InfoIcon from '@mui/icons-material/Info';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const drawerWidth = 240;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 0),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

//rotas
const itemList = [
  {
    text: "Home",
    icon: <HomeIcon />,
    to: "/",
    isFromApp: true
  },
  {
    text: "Explore",
    icon: <InfoIcon />,
    to: "/about"
  },
  {
    text: "FAQ",
    icon: <HelpOutlineIcon />,
    to: "/faq"
  },
  {
    text: "Contact",
    icon: <EmailIcon />,
    to: "/contact"
  }
];


const DrawerItem = () => {
  const location = useLocation();
  const pathname = location.pathname;

  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton
      color="inherit"
      aria-label="open drawer"
      edge="end"
      onClick={handleDrawerOpen}
      sx={{ ...(open && { display: 'none' }) }}
      >
        <MenuIcon />
      </IconButton>

      <Drawer
      sx={{
          flexGrow: 1,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
          width: drawerWidth,
          background: 'rgba(9, 9, 9, 0.5)'
          },
      }}
      // variant="persistent"
      anchor="right"
      open={open}
      >
        <DrawerHeader style={{ background: 'rgba(9, 9, 9, 0.5)' }}>
          <IconButton onClick={handleDrawerClose} style={{ color: 'white' }}>
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {itemList.map( ( item ) => {
            const { text } = item;
            if (item.to == pathname) {
              return (
                  <ListItem key={text}>
                    <ListItemIcon
                sx={{
                  color: 'white',
                  "&:hover":{
                    backgroundColor: 'transparent',
                    color: 'white',
                  }
                }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={text} style={{
                  color: 'white'
                  }} />
              </ListItem>
              )
          } else {
            return(
              <ListItem 
              key={text} 
              component={Link}
              onClick={handleDrawerClose}
              to={item.to}
              state={{ isFromApp: item?.isFromApp ? item.isFromApp : false }}
              sx={{
                color: 'white',
                "&:hover": {
                  backgroundColor: 'rgba(9, 9, 9, 0.5)',
                  color: 'white',
                }
              }}
              >
                <ListItemIcon
                sx={{
                  color: 'white',
                  "&:hover":{
                    backgroundColor: 'transparent',
                    color: 'white',
                  }
                }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={text} style={{
                  color: 'white'
                  }} />
              </ListItem>
            )}
          })}
        </List>
      </Drawer>
    </>
  )
}

export default DrawerItem;