import React from 'react';
import { useEffect, useRef, useState } from 'react';
import { CircularProgress } from '@mui/material';

const LazyLoadedIframe = ({ src, options }) => {
  const iframeRef = useRef(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // setLoading(false);
          iframeRef.current.src = src;
          observer.unobserve(entry.target);
        }
      });
    }, { threshold: 0.5 });

    observer.observe(iframeRef.current);

    return () => {
      observer.disconnect();
    };
  }, [src]);

  return <>
      {loading && <CircularProgress style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1001, color: 'white' }} />}
      <iframe ref={iframeRef} {...options} onLoad={() => setLoading(false)} />;
      </>
};

export default LazyLoadedIframe;