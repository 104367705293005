import React from "react";
import Terminal from "react-animated-term";

const termLines = [
  {
    text: "npm run start",
    cmd: true,
    delay: 400
  },
  {
    text: "> metastability@0.1.0 start",
    cmd: false,
    delay: 2000
  },
  {
    text: "> react-scripts start",
    cmd: false,
    frames: [
      {
        text: "[---------------------] 0/1000",
        delay: 600
      },
      {
        text: "[#--------------------] 29/1000",
        delay: 600
      },
      {
        text: "[##-------------------] 45/1000",
        delay: 700
      },
      {
        text: "[###------------------] 51/1000",
        delay: 900
      },
      {
        text: "[####-----------------] 64/1000",
        delay: 700
      },
      {
        text: "[#####----------------] 78/1000",
        delay: 900
      },
      {
        text: "[######---------------] 85/1000",
        delay: 1100
      },
      {
        text: "[#######--------------] 100/1000",
        delay: 1200
      },
      {
        text: "[############---------] 500/1000",
        delay: 1800
      },
      {
        text: "[#####################] 1000/1000",
        delay: 700
      }
    ]
  },
  {
    text: ">",
    cmd: false,
    delay: 2000
  },
  {
    text: "Compiled successfully!",
    cmd: false,
    delay: 2000
  },
  {
    text: ">",
    cmd: false,
    delay: 2000
  },
  {
    text: `You can now view metastability in the browser.

    Local:            ${window.location.href}
    On Your Network:  http://192.168.0.1:5555
  
  Note that the development build is not optimized.
  To create a production build, use npm run build.
  
  webpack compiled successfully`,
    cmd: false
  }
];

const CommandLine = () => (
  <div id="command-terminale">
<div id="bar" style={{textAlign: 'center', width: '450px', height: '25px', backgroundColor: '#DAD9D9',margin: '0 auto',fontFamily: 'monospace',padding: 'auto',float: 'none',borderRadius: '5px 5px 0% 0%'}}>
    <div id="red" style={{backgroundColor: '#E94B35',borderRadius: '100%',width: '12px',height: '12px',margin: '0 auto',left: '-47%',bottom: '-20%',position:'relative'}}>
    </div>
    <div id="yellow" style={{backgroundColor: '#f0f000',borderRadius: '100%',width: '12px',height: '12px',margin: '0 auto',left: '-42%',bottom: '29%',position:'relative',display: 'block'}}>
    </div>
    <div id="green" style={{backgroundColor: '#1AAF5C',borderRadius: '100%', width: '12px',height: '12px',margin: '0 auto',left: '-37%',bottom: '77%', position:'relative',display: 'block'}}>
    </div>
</div>
    <div id="screen" style={{backgroundColor: '#33485E',width: '450px',height: '270px',borderRadius: '0% 0% 2% 2%',margin: '0 auto', padding: '0.1rem 0.5rem'}}>
        <div style={{color: '#fff',fontFamily: 'monospace',fontSize: '15px',textAlign: 'left',position: 'static'}}>
        <Terminal lines={termLines} />
    </div>
    </div>
    </div>
);

export default CommandLine;