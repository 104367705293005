import React from 'react';
import { useEffect, useRef, useState } from 'react';
import { CircularProgress } from '@mui/material';

const LazyLoadedVideo = ({ src, options }) => {
  const videoRef = useRef(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          videoRef.current.src = src;
          observer.unobserve(entry.target);
        }
      });
    }, { threshold: 0.5 });

    observer.observe(videoRef.current);

    return () => {
      observer.disconnect();
    };
  }, [src]);
  
  // useEffect(() => {
  //   videoRef.current.onloadeddata = () => {
  //     setLoading(false);
  //   };

  //   return () => {
  //     videoRef.current.onloadeddata = null;
  //   };
  // }, []);

  return (
    <>
      {loading && <CircularProgress style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1001, color: 'white' }} />}
      <video ref={videoRef} {...options} onLoadedData={() => setLoading(false)}>
        <source type="video/mp4"/>
      </video>
    </>)
};

export default LazyLoadedVideo;
