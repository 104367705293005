import React, { useEffect } from 'react';
import { Box, Grid, styled } from '@mui/material';
import Title from './Title';
// img
// import imgDetail from '../assets/2s.png';
// import imgDetail2 from '../assets/5s.png';
import CommandLine from './Anima/cmd';
import { useLocation } from 'react-router-dom';
// import Scene from './Anima/scene';
 import me_logo from '../assets/logo-removebg-preview.png';
// img
 // import me_ai from '../assets/me_ai.gif';

const GetStarted = () => {

  const location = useLocation();

  // Check if the previous location is from another application page
  const isFromApp = location?.state && location.state.isFromApp;

  const CustomGrid = styled(Grid)(() => ({
    background: 'linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab)',
    backgroundSize: '400% 400%',
    animation: 'gradient 15s ease infinite',
    zIndex: 1,
    display: 'flex',
    position: 'relative'
    // height: "100vh"
  }));

  const CustomGridItem = styled(Grid)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  });

  // const CustomTypography = styled(Typography)({
  //   fontSize: '1.1rem',
  //   textAlign: 'start',
  //   lineHeight: '1.5',
  //   color: '#515151',
  //   marginTop: '1.5rem'
  // });

  useEffect(() => {
    if (isFromApp) {
      if (document.getElementById('get_dtarted_id')?.style) { document.getElementById('get_dtarted_id').style['opacity'] = '1'; }
      if (document.getElementById('command-terminale')?.style) { document.getElementById('command-terminale').style['transition'] = '3s'; }
      if (document.getElementById('command-terminale')?.style) { document.getElementById('command-terminale').style['transform'] = 'translateY(-7rem)'; }
    } else {
      setTimeout(() => {
        if (document.getElementById('get_dtarted_id')?.style) { document.getElementById('get_dtarted_id').style['opacity'] = '1'; }
        if (document.getElementById('command-terminale')?.style) { document.getElementById('command-terminale').style['transition'] = '3s'; }
        if (document.getElementById('command-terminale')?.style) { document.getElementById('command-terminale').style['transform'] = 'translateY(-7rem)'; }
      }, 3500);
    }
  }, []);
// Parse the input and generate the glTF output

  return (
    <CustomGrid
      id='get_dtarted_id'
      container
      spacing={{ xs: 4, sm: 4, md: 0 }}
      sx={
        (theme) => ({
          [theme.breakpoints.down('md')]: {
            py: 10,
            px: 2,
            background: 'radial-gradient(at 100% 0%, rgb(0 0 0 / 63%) 0px, #56565630 50%),radial-gradient(at 92% 64%, rgb(44 85 74 / 69%) 0px, #00000036 50%),radial-gradient(at 35% 0%, #00000066 0px, transparent 50%),radial-gradient(at 0% 0%, #08090b 0px, transparent 50%),radial-gradient(at 76% 0%, #548bbd 0px, #72727200 50%),radial-gradient(at 86% 47%, #6c719c 0px, transparent 50%),radial-gradient(at 43% 59%, #658b79 0px, transparent 50%),radial-gradient(at 6% 100%, #32496a 0px, transparent 50%),radial-gradient(at 42% 100%, #6e6e80 0px, transparent 50%),radial-gradient(at 1% 53%, #6b6a62 0px, transparent 50%),radial-gradient(at 100% 100%, #4a98b8 0px, transparent 50%),radial-gradient(at 63% 100%, #69c3d8 0px, transparent 50%),radial-gradient(at 20% 100%, #799bc8 0px, transparent 50%);',
            opacity: 0,
            transition: '5s',
            paddingTop: '0rem !important'
          },
          [theme.breakpoints.up('md')]: {
            py: 10,
            px: 2,
            background: 'radial-gradient(at 100% 0%, rgb(0 0 0 / 63%) 0px, #56565630 50%),radial-gradient(at 92% 64%, rgb(44 85 74 / 69%) 0px, #00000036 50%),radial-gradient(at 35% 0%, #00000066 0px, transparent 50%),radial-gradient(at 0% 0%, #08090b 0px, transparent 50%),radial-gradient(at 76% 0%, #548bbd 0px, #72727200 50%),radial-gradient(at 86% 47%, #6c719c 0px, transparent 50%),radial-gradient(at 43% 59%, #658b79 0px, transparent 50%),radial-gradient(at 6% 100%, #32496a 0px, transparent 50%),radial-gradient(at 42% 100%, #6e6e80 0px, transparent 50%),radial-gradient(at 1% 53%, #6b6a62 0px, transparent 50%),radial-gradient(at 100% 100%, #4a98b8 0px, transparent 50%),radial-gradient(at 63% 100%, #69c3d8 0px, transparent 50%),radial-gradient(at 20% 100%, #799bc8 0px, transparent 50%);',
            opacity: 0,
            transition: '5s'
          }
        })

        }>
      <CustomGridItem item xs={12} sm={8} md={7} component="section">
        <Box
          component="article"
          sx={
            (theme) => ({
              [theme.breakpoints.down('md')]: {
                px: 0,
                paddingLeft: 0,
                marginLeft: 0,
                paddingTop: '1rem !important'
              },
              [theme.breakpoints.up('md')]: {
                px: 4,
                marginLeft: '3rem' 
              }
            })}
          style={{ textWrap: 'balance'}}>
          <Title text={'Web Solutions'} textAlign={'start'} color={'#f9f9f9'} />
          <div className='custom-typography'>
            <div className='custom-typography-body'>
              <h1 id='web_id'><code>::web</code></h1>
              <p className='custom-typography-h1'>Our team consists of a highly skilled and experienced groups of professionals who specialize in web development and design. With a deep understanding of industry best practices and emerging trends, we are committed to create websites that provide seamless functionality and exceptional user experiences.</p>
              <blockquote className='custom-typography-blockquote' id='custom_typography_blockquote_1'>
                <p className='custom-typography-h1'>We bring a holistic approach to every project. We recognize that a successful web-product must align with our clients goals and target audience. </p>
                <p className='custom-typography-h1'>We prioritize usability and accessibility. Our team employs the latest web technologies and coding standards to build websites that are fast, secure, and easily maintainable. </p>
              </blockquote>
            </div>
          </div>
        </Box>
      </CustomGridItem>

      <Grid item xs={12} sm={4}  sx={
            (theme) => ({
              [theme.breakpoints.down('md')]: {
                px: 0,
                paddingLeft: '0 !important',
                paddingTop: '0 !important',
                marginLeft: 0,
                transform: 'scale(0.8)',
                padding: '0 !important'
              },
              [theme.breakpoints.up('md')]: {
              }
            })}
            md={5} style={{ alignSelf: 'center' }} >
        <CommandLine />
      </Grid>

      <Grid
        style={{ paddingTop: '2.5rem', transform: 'scale(1.75)' }}
        item
        xs={12}
        sm={5}
        md={5}
        sx={
          (theme) => ({
            [theme.breakpoints.down('md')]: {
              order: { xs: 4, sm: 4, md: 3 },
              paddingTop: '0 !important',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              paddingLeft: '0 !important',
            },
            [theme.breakpoints.up('md')]: {
              order: { xs: 4, sm: 4, md: 3 },
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              paddingTop: 0,
            }
          })
          }>
            <div className="cube">
            <div className="top">
              <img
                src={me_logo}
                width={100}
                height={100}
                alt="Metastability"
              />
            </div>
            <div className="right">
              <img
                src={me_logo}
                width={100}
                height={100}
                alt="Metastability"
              />
              </div>
            <div className="bottom">
              <img
                src={me_logo}
                width={100}
                height={100}
                alt="Metastability"
              />
              </div>
            <div className="left">
              <img
                src={me_logo}
                width={100}
                height={100}
                alt="Metastability"
              />
              </div>
            <div className="front">
              <img
                src={me_logo}
                width={100}
                height={100}
                alt="Metastability"
              />
              </div>
            <div className="back">
              <img
                src={me_logo}
                width={100}
                height={100}
                alt="Metastability"
              />
              </div>
          </div>
          {/* <img src={me_ai} alt='me' style={{ width: '80%', filter: 'brightness(3)'}}/> */}
      </Grid>

      <CustomGridItem
        item
        xs={12}
        sm={7}
        md={7}
        sx={{
          order: { xs: 3, sm: 3, md: 4 }
        }}>
        <Box
          style={{ paddingTop: '5rem' }}
          component="article"
          sx={
            (theme) => ({
              [theme.breakpoints.down('md')]: {
                px: 0,
                paddingLeft: 0,
                marginLeft: 0,
                paddingTop: '0 !important'
              },
              [theme.breakpoints.up('md')]: {
                px: 0
              }
            })}>
          <Title text={'Artificial Intelligence Solutions'} textAlign={'start'} color={'#f9f9f9'} />
          <div className='custom-typography'>
            <div className='custom-typography-body'>
              <h1 id='ai_id'><code>::ai ::ml ::dl</code></h1>
              <p className='custom-typography-h1'>Our team of highly skilled and experienced AI and machine learning experts is at the forefront of technological advancements.</p>
              <p className='custom-typography-h1'>With a deep understanding of cutting-edge AI techniques and machine learning algorithms, we create intelligent software applications that push the boundaries of whats possible.</p>
              <blockquote className='custom-typography-blockquote' id='custom_typography_blockquote_2'>
                <p className='custom-typography-h1'>Using state-of-the-art technology, advanced algorithms, and powerful computing infrastructure, our team develops software solutions that are not only intelligent but also highly efficient and scalable.</p>
                <p className='custom-typography-h1'>We harness the power of big data, extracting valuable insights and training our applications to deliver accurate predictions and automate complex tasks. </p>
              </blockquote>
            </div>
          </div>
        </Box>
      </CustomGridItem>
    </CustomGrid >
  );
};

export default GetStarted;
