import React, { useState } from 'react';
import { Autocomplete, Box, Button, Stack, TextField, TextareaAutosize, Card } from '@mui/material';
import Grid from '@mui/material/Grid';
import Title from './Title';
import Paragraph from './Paragraph';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const Details = () => {

  const [form, setFromed] = useState(true);

  let tg = {
    token: process.env.REACT_APP_BOT_TOKEN,
    chat_id: process.env.REACT_APP_CHAT_ID
  }

  const fileToDataUri = (file) => new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      resolve(event.target.result);
    };
    reader.readAsDataURL(file);
  })

  const [dataUri, setDataUri] = useState('');
  const [fileName, setFileName] = useState('filename');

  const onChange = (file) => {
    // console.log('dataUri', dataUri);

    if (!file) {
      setDataUri('');
      return;
    }

    fileToDataUri(file)
      .then(dataUri => {
        setDataUri(dataUri);
        // console.log('[onChange] file', file);
        setFileName(file?.name);
      })
  }

  function dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    var ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], { type: mimeString });
    return blob;
  }


  const sendMessage = (text) => {
    const url = `https://api.telegram.org/bot${tg.token}/sendMessage`;

    const obj = {
      chat_id: tg.chat_id,
      text: text
    };

    const xht = new XMLHttpRequest();
    xht.open("POST", url, true);
    xht.setRequestHeader("Content-type", "application/json; charset=UTF-8");
    xht.send(JSON.stringify(obj));
    setFromed(false);

    const blob = dataURItoBlob(dataUri);

    const fileData = new FormData();
    fileData.append('chat_id', tg.chat_id);
    fileData.append('document', blob, fileName);

    const request = new XMLHttpRequest();
    request.open('POST', `https://api.telegram.org/bot${tg.token}/sendDocument`);
    request.send(fileData);
  }

  const handleSubmit = (event) => {
    const ident = new Date().toJSON().slice(0, 10).split('-');
    const n = parseInt(ident[0]) + parseInt(ident[1]) * 10 + parseInt(ident[2]);

    event.preventDefault();
    const data = new FormData(event.currentTarget);

    let hostname = 'default';
    if (typeof window !== "undefined") {
      hostname = window?.location?.hostname;
      // console.log('window', window);
    }

    const text = `🟢 New application for development №${n}⠀
    [hostname: ${hostname}]

    Name/Company: ${data.get('name')},⠀

    Email/Phone: ${data.get('email')},⠀

    Product: ${data.get('mainOptions')}

    Type: ${data.get('options') ? data.get('options') : '-'}

    Description: "${data.get('brief') ? data.get('brief') : '-'}"`;
    sendMessage(text, n);
  };

  const options = ['eCommerce website', 'Business website', 'Blog website', 'Portfolio website', 'Event website', 'Personal website', 'Membership website', 'Nonprofit website', 'Informational website', 'Online forum', 'Community website', 'Startup website', 'Consulting website', 'Booking website', 'Petition website', 'School website', 'Hobby website', 'Interactive website', 'Entertainment website', 'Wedding website', 'Travel website', 'Directory website', 'Landing page', 'News and magazine website', 'Memorial website', 'Subscription website', 'Kid-friendly website', 'Other']

  const mainOptions = ['Web Product', 'Artificial Intelligence Product', 'Software Product', 'Other'];

  const [value, setValue] = React.useState(mainOptions[0]);
  const [inputValue, setInputValue] = React.useState('');

  // 1) After reciving the Customer Data from (https://NONAME.ai/contact), generate link for login and accept registration on merex.NONAME.ai platform for customer
  // 2) After he was gone through link create offer and chat interface for customer and future contractors

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Grid
  container
  spacing={0}
  direction="column"
  alignItems="center"
  justifyContent="center"
  sx={{ minHeight: '100vh', py: 5 }}
>
      <Card sx={{ maxWidth: 600, 
        background: 'rgb(9, 9, 9)',
        margin: 4 }}>
    <Stack
      component="section"
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        py: 4,
        px: 2,
      }}>
      {form ? <>
        <Title text={'Send An Offer'} textAlign={'center'} />
        <Paragraph
          text={
            'We are here to start developing your product and answer any questions, concerns or suggestions you may have.'
          }
          maxWidth={'sm'}
          mx={0}
          textAlign={'center'}
        />

        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit}
          sx={{
            mt: 1,
            py: 2
          }}>
          <TextField
            margin="normal"
            required
            fullWidth
            name="name"
            label="Name or Company Name"
            type="text"
            id="name"
            // style={{ background: 'white' }}
            variant='outlined'
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address or Phone Number"
            name="email"
            // style={{ background: 'white' }}
            autoComplete="email"
          />
          <Autocomplete
            margin="normal"
            value={value}
            fullWidth
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            id="mainOptions"
            name="mainOptions"
            style={{ marginBottom: '1rem', marginTop: '1rem' }}
            options={mainOptions}
            renderInput={(params) => <TextField {...params} name="mainOptions" label="Solution" />}
          />
          {inputValue == 'Web Product' ? <Autocomplete
            margin="normal"
            disablePortal
            id="options"
            label="Type of website"
            // style={{ background: 'white' }}
            fullWidth
            name="options"
            options={options}
            renderInput={(params) => <TextField {...params} name="options" label="Type of Website" />}
            autoFocus
          /> : ''}

          <TextareaAutosize
            margin="normal"
            maxRows={4}
            aria-label="maximum height"
            placeholder="You can describe the task here in brief or attach the file"
            defaultValue=""
            id="brief"
            name="brief"
            style={{
              width: '100%',
              padding: '0.1rem 0.8rem',
              height: '5rem',
              marginTop: '1rem',
              background: 'transparent',
              borderColor: 'rgba(128, 128, 128, 0.61)',
              borderWidth: '1px',
              borderRadius: '5px',
              color: 'white'
            }}
          />

          <div>
            <input type="file" id="fileload" onChange={(event) => onChange(event.target.files[0] || null)} />
            <span style={{ fontSize: '8.5pt' }}>document/archive format only*</span>
          </div>
          <Button
            variant="contained"
            fullWidth
            type="submit"
            size="medium"
            sx={{
              fontSize: '0.9rem',
              textTransform: 'capitalize',
              color: 'white',
              py: 2,
              mt: 3,
              mb: 2,
              borderRadius: 5,
              backgroundColor: '#14192d',
              '&:hover': {
                backgroundColor: '#1e2a5a'
              }
            }}>
            send
          </Button>
        </Box>
      </> : <>
        <Title text={'Done!'} textAlign={'center'} />
        <Paragraph
          text={
            'Application successfully received. We will contact you.'
          }
          maxWidth={'sm'}
          mx={0}
          textAlign={'center'}
        />
      </>}
    </Stack>
    </Card>
    </Grid>
    </ThemeProvider>
  );
};

export default Details;